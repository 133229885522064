<div class="supy-select-branch-and-supplier-dialog">
  <supy-dialog
    position="center"
    initialState="open"
    width="40vw"
    (dialogClosed)="onDialogClosed($event)"
    (dialogClosing)="onDialogClosing($event)"
    (dialogOpened)="onDialogOpened($event)"
    (dialogOpening)="onDialogOpening($event)"
  >
    <supy-dialog-header>
      <h3 class="supy-select-branch-and-supplier-dialog__header-title">{{ title }}</h3>
    </supy-dialog-header>
    <supy-dialog-content>
      <div [formGroup]="form" class="supy-select-branch-and-supplier-dialog__form">
        <div>
          <supy-combo-box
            formControlName="branchIds"
            i18n-placeholder="@@branchSelect"
            displayKey="name"
            valueKey="id"
            titleKey="name"
            subtitleKey="phone"
            pipeKey="email"
            placeholder="Select Branch"
            name="branches"
            [list]="retailerBranches$ | async"
            [localSearch]="true"
          ></supy-combo-box>
        </div>
        <div>
          <supy-combo-box
            formControlName="supplierIds"
            i18n-placeholder="@@common.selectSupplier"
            displayKey="name"
            valueKey="id"
            titleKey="name"
            subtitleKey="phone"
            [disabled]="!form.get('branchIds')?.value?.length"
            pipeKey="email"
            placeholder="Select Supplier"
            name="suppliers"
            [list]="suppliers$ | async"
            [isLoading]="areSuppliersLoading$ | async"
            [localSearch]="true"
          ></supy-combo-box>
        </div>
      </div>
    </supy-dialog-content>
    <supy-dialog-footer>
      <div class="supy-select-branch-and-supplier-dialog__buttons">
        <supy-button (buttonClick)="onCancelClick()" color="default" name="cancel">{{ cancelText }}</supy-button>
        <supy-button (buttonClick)="onConfirmClick()" [disabled]="form.invalid" color="primary" name="confirm">{{
          confirmText
        }}</supy-button>
      </div>
    </supy-dialog-footer>
  </supy-dialog>
</div>
