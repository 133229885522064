import { BehaviorSubject, finalize, map, Observable, of, switchMap, takeUntil, tap } from 'rxjs';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IDialogCancellableEventArgs, IDialogEventArgs, OverlaySettings } from '@infragistics/igniteui-angular';
import { Select } from '@ngxs/store';

import { Destroyable, Query, Supplier, SupplierType, ViewBranch } from '@supy/common';
import { DialogComponent, IDialogComponent } from '@supy/components';
import { BranchesService, CurrentRetailerState } from '@supy/retailers';

export interface SelectBranchAndSupplier {
  readonly branchIds: string[];
  readonly supplierIds: string[];
}

@Component({
  selector: 'supy-select-branch-and-supplier-dialog',
  templateUrl: './select-branch-and-supplier-dialog.component.html',
  styleUrls: ['./select-branch-and-supplier-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectBranchAndSupplierDialogComponent extends Destroyable implements IDialogComponent, OnInit {
  @Select(CurrentRetailerState.branches) readonly retailerBranches$: Observable<ViewBranch[]>;

  @Input() readonly title: string;
  @Input() readonly cancelText?: string = $localize`:@@cancel:Cancel`;
  @Input() readonly confirmText?: string = $localize`:@@common.actions.submit:Submit`;
  @Input() readonly navigateOnConfirm?: boolean;
  @Input() readonly redirectUrl?: string[];
  @Input() readonly overlaySettings?: OverlaySettings;

  @Output() readonly dialogClosing = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogClosed = new EventEmitter<IDialogEventArgs>();
  @Output() readonly dialogOpening = new EventEmitter<IDialogCancellableEventArgs>();
  @Output() readonly dialogOpened = new EventEmitter<IDialogEventArgs>();
  @Output() readonly confirm = new EventEmitter<SelectBranchAndSupplier>();
  @Output() readonly cancel = new EventEmitter<void>();

  readonly areSuppliersLoadingChange = new BehaviorSubject<boolean>(false);
  readonly areSuppliersLoading$ = this.areSuppliersLoadingChange.asObservable();

  readonly suppliersChange = new BehaviorSubject<Supplier[]>([]);
  readonly suppliers$ = this.suppliersChange.asObservable();

  protected readonly form = new FormGroup({
    branchIds: new FormControl<string[] | null>(null, Validators.required),
    supplierIds: new FormControl<string[] | null>({ value: null, disabled: true }, Validators.required),
  });

  @ViewChild(DialogComponent, { static: true }) readonly dialog: DialogComponent;

  get supplierIdsControl(): FormControl<string[] | null> {
    return this.form.get('supplierIds') as FormControl<string[] | null>;
  }

  constructor(
    private readonly router: Router,
    private readonly branchesService: BranchesService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.form
      .get('branchIds')
      .valueChanges.pipe(
        takeUntil(this.destroyed$),
        tap(() => this.form.get('supplierIds').reset()),
        switchMap(branchIds => this.getSuppliersByBranchIds(branchIds)),
        map(suppliers =>
          suppliers.map(supplier => ({
            ...supplier,
            name: supplier.channels[0].displayName?.supplier ?? supplier.name,
          })),
        ),
      )
      .subscribe(suppliers => this.suppliersChange.next(suppliers));
  }

  getSuppliersByBranchIds(branchIds: string[]): Observable<Supplier[]> {
    if (!branchIds?.length) {
      this.supplierIdsControl.reset();
      this.supplierIdsControl.disable();

      return of([]) as Observable<Supplier[]>;
    }

    this.areSuppliersLoadingChange.next(true);

    const query = new Query<Supplier>({
      filtering: [
        { by: 'state', op: 'eq', match: 'active' },
        { by: 'type', op: 'neq', match: SupplierType.centralKitchen },
      ],
    });

    return this.branchesService.getBranchesSuppliers(branchIds, query).pipe(
      map(({ data }) => data),
      tap(() => this.supplierIdsControl.enable()),
      finalize(() => this.areSuppliersLoadingChange.next(false)),
    );
  }

  onDialogClosing(event: IDialogCancellableEventArgs): void {
    this.dialogClosing.emit(event);
  }

  onDialogClosed(event: IDialogEventArgs): void {
    this.dialogClosed.emit(event);
  }

  onDialogOpening(event: IDialogCancellableEventArgs): void {
    this.dialogOpening.emit(event);
  }

  onDialogOpened(event: IDialogEventArgs): void {
    this.dialogOpened.emit(event);
  }

  onCancelClick(): void {
    this.closeDialog();
    this.cancel.emit();
  }

  onConfirmClick(): void {
    this.closeDialog();

    const value = this.form.value as SelectBranchAndSupplier;

    this.confirm.emit(value);

    if (this.navigateOnConfirm) {
      void this.router.navigate(this.redirectUrl, {
        queryParams: { branchId: value.branchIds[0], supplierId: value.supplierIds[0] },
      });
    }
  }

  openDialog(): void {
    this.dialog.openDialog();
  }

  closeDialog(): void {
    this.dialog.closeDialog();
  }
}
