<article class="place-order">
  <supy-loading-overlay *ngIf="isLoading()"></supy-loading-overlay>

  <div class="place-order__header place-order__section">
    <div class="place-order__header-info">
      <div *supyContext="channel() as channel" class="place-order__row">
        <supy-avatar
          [initials]="channel?.displayName?.supplier ?? channel?.partyInfo?.supplier?.name"
          [src]="channel?.partyInfo?.supplier.logoUrl"
          size="medium"
        ></supy-avatar>
        <h4>{{ channel?.displayName?.supplier ?? channel?.partyInfo?.supplier?.name }}</h4>
      </div>
      <div *supyToggle="toggleFeature.Inventory" class="place-order__row">
        <div *ngIf="isCKv1()" class="place-order__out-stock-wrapper">
          <supy-icon name="bag-cross" color="error" size="small"></supy-icon>
          <span i18n="@@placeOrder.outOfStock">Out of stock</span>
        </div>
        <supy-button name="fill-all-to-par" color="secondary" (buttonClick)="onFillManyToPar()">
          <supy-icon name="box-tick" color="primary"></supy-icon>
          <span i18n="@@common.actions.fillAllToPar">Fill all to Par</span>
        </supy-button>
      </div>
    </div>
    <div class="place-order__row">
      <supy-filter-group
        *ngIf="filtersGroup"
        [filterGroup]="filtersGroup"
        [latency]="400"
        (filtersChange)="onFilterChanges($event)"
        (filtersReset)="onFiltersClear()"
      ></supy-filter-group>
    </div>
  </div>

  <div class="place-order__content">
    <supy-grid
      class="place-order__grid"
      primaryKey="productId"
      cellSelection="none"
      [height]="null"
      [paginationMode]="paginationMode"
      [page]="page"
      [perPage]="limit"
      [prevPageDisabled]="page === 0"
      [nextPageDisabled]="count < limit"
      (pageChange)="onPageChange($event)"
      [data]="data()"
    >
      <supy-grid-column
        header="Supplier Item Code"
        i18n-header="@@supplierItemCode"
        field="itemCode"
        width="15%"
      ></supy-grid-column>
      <supy-grid-column width="25%" header="Package Item Name" i18n-header="@@packageItemName">
        <ng-template supyGridCell let-value let-rowData="rowData">
          {{ getLocalizedName(rowData.name) }}
          <supy-icon
            *ngIf="isCKv1() && !rowData.orderable"
            title="Out of stock"
            name="bag-cross"
            color="error"
            size="small"
          ></supy-icon>
        </ng-template>
      </supy-grid-column>
      <supy-grid-column
        width="15%"
        header="Category"
        i18n-header="@@category"
        field="category.name.en"
      ></supy-grid-column>
      <supy-grid-column
        *supyToggle="toggleFeature.Inventory"
        width="15%"
        header="Stock/Par"
        i18n-header="@@stockParDiff"
        field="quantityLevels"
      >
        <ng-template supyGridCell let-value let-rowData="rowData">
          <div class="place-order__row--space-between">
            <span>
              {{ value?.stock ?? 0 | supyPrecision: 2 }}
              {{ value?.par ? ' / ' + (value.par | supyPrecision: 2) : '' }}
              ({{ rowData.packaging.baseUnit.name }})
            </span>
            <supy-button
              *ngIf="shouldShowFillToParButton(rowData)"
              name="fill-to-par"
              variant="icon"
              [disabled]="isFillToParDisabled(rowData)"
              (buttonClick)="onFillToPar(rowData)"
            >
              <supy-icon name="box-tick" [color]="isFillToParDisabled(rowData) ? 'secondary' : 'primary'"></supy-icon>
            </supy-button>
          </div>
        </ng-template>
      </supy-grid-column>
      <ng-container *ngIf="isCKv1(); else price">
        <supy-grid-column
          *ngIf="!pushToInventorySettings()?.hideCKStock"
          width="10%"
          header="Supplier Stock Qty"
          i18n-header="@@grid.headers.supplierStockQty.label"
          field="ckStock"
        >
          <ng-template supyGridCell let-value let-rowData="rowData"
            >{{ value ?? 0 | supyPrecision: 2 }} ({{ rowData.packaging.baseUnit.name }})</ng-template
          >
        </supy-grid-column>
      </ng-container>

      <ng-template #price>
        <supy-grid-column
          width="10%"
          header="Price ({{ currency() }})"
          i18n-header="@@common.priceInterpolation"
          field="price"
        >
          <ng-template supyGridCell let-value>{{ value | supyPrecision: currencyPrecision() }}</ng-template>
        </supy-grid-column>
      </ng-template>

      <supy-grid-column width="20%" header="Quantity" i18n-header="@@quantity" field="quantity">
        <ng-template supyGridCell let-rowData="rowData" let-value="value">
          <supy-counter-input
            [disabled]="!rowData.orderable"
            [value]="getComputedQuantityValue(rowData.id)"
            [step]="1"
            [min]="0"
            name="qty"
            (valueChange)="onQuantityChange(rowData, $event)"
          ></supy-counter-input>
        </ng-template>
      </supy-grid-column>
    </supy-grid>
  </div>

  <div *supyContext="order() as order" class="place-order__footer place-order__section place-order__row--space-between">
    <div class="place-order__sum">
      <ng-container *ngIf="isCKv1()">
        <h6 class="place-order__sum-label" i18n="@@placeOrder.estimatedQty">Estimated quantity:</h6>
      </ng-container>
      <ng-container *ngIf="!isCKv1()">
        <h6 class="place-order__sum-label" i18n="@@estimatedPrice">Estimated Price:</h6>
      </ng-container>
      <h4 class="place-order__sum-value">
        {{ isCKv1() ? (totalQuantity() | supyPrecision: 2) : (totalPrice() | currency: currency()) }}
      </h4>
    </div>
    <div *supyContext="isFooterDisabled() as isFooterDisabled" class="place-order__row">
      <supy-button
        *ngIf="order?.status === orderStatus.Draft"
        i18n="@@common.actions.updateDraft"
        name="save-order"
        color="secondary"
        [disabled]="isFooterDisabled"
        (buttonClick)="onSave(order?.id)"
        >Update Draft</supy-button
      >
      <supy-button
        name="review-order"
        color="primary"
        [disabled]="isFooterDisabled"
        (buttonClick)="onReview()"
        i18n="@@common.actions.orderReview"
        >Review Order</supy-button
      >
    </div>
  </div>
</article>
